<template>
  <v-container id="main-container">
    <v-row justify="center">
      <v-col lg="7" md="8" xl="5" sm="10" xs="12" cols="12">
        <v-card :elevation="0">
          <v-card-title class="mt-3 justify-center">
            <h3 class="blue-grey--text text--darken-2">Ndryshoni fjalëkalimin tuaj</h3>
          </v-card-title>
          <v-card-text>
            <v-container>

              <!--current password-->
              <v-row dense class="justify-center">
                <v-col cols="4">
                  <v-text-field label="Fjalëkalimi aktual" v-model="currentPassword" type="password"/>
                </v-col>
              </v-row>

              <!--new password-->
              <v-row dense class="justify-center">
                <v-col cols="4">
                  <v-text-field counter label="Fjalëkalimi i ri" v-model="newPassword" hint="Të paktën 6 karaktere"
                                type="password"/>
                </v-col>
              </v-row>

              <!--verify password-->
              <v-row dense class="justify-center">
                <v-col cols="4">
                  <v-text-field counter label="Konfirmo fjalëkalimin" v-model="confirmPassword"
                                hint="Të paktën 6 karaktere" type="password"/>
                </v-col>
              </v-row>

              <!--error alert-->
              <v-row class="justify-center">
                <v-col cols="10" v-if="errorMessage.length > 0">
                  <v-alert text dismissible type="error">{{ errorMessage }}</v-alert>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>

          <v-card-actions class="justify-center pb-5">
            <v-btn color="blue darken-3" class="ml-6 px-5" outlined @click="confirm">
              <v-icon left>mdi-check-underline</v-icon>
              Konfirmo
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserService from "@/service/UserService";

export default {
  name: 'ResetPassword',

  data: function () {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      errorMessage: ''
    }
  },

  methods: {
    async confirm() {
      if (this.currentPassword.length < 7 || this.newPassword.length < 7 || this.confirmPassword.length < 7) {
        this.errorMessage = 'Fjalëkalimi duhet të jepet më i gjatë!';
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = "Fjalëkalimet nuk korrespondojnë!"
        return;
      }
      try {
        await UserService.updatePassword(this.currentPassword, this.newPassword);
        this.errorMessage = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.currentPassword = '';
      } catch (e) {
        this.errorMessage = e.message;
      }
    }
  }
}
</script>